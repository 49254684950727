html, body #root {
  height: 100vh;
  margin: 0;
  padding: 0;
}

#react-qrcode-logo {
  width: 100% !important;
  height: 100% !important;
}

#logo {
  fill: white;
}

a {
  color: #f62252;
}

.CustomerID {
  font-family: monospace;
  font-size: 12px;
  font-weight: bold;
  color: #222;
  background: #eee;
  border-radius: 0.2rem;
  border: 1px solid #ddd;
  padding: 0.2rem;
}

.BigBox {
  background: rgba(0,0,0,0.05);
  box-shadow: 1px 1px 2px rgba(0,0,0,0.2);
  border-radius: 1rem;
  padding: 2rem;
  text-align: center;
  min-width: 10rem;
}

.BigBox_Number {
  color: #f62252;
  font-family: 'Nunito', sans-serif;
  font-weight: 700;
  font-size: 8rem;
  line-height: 8rem;
}

.BigBox_Label {
  font-weight: 700;
}

.PeakTimes_Container {
  background: rgba(0,0,0,0.05);
  border-radius: 1rem;
}

.PeakTimes {
  font-family: 'Nunito', sans-serif;
  font-size: 4rem;
  color: #f62252;
}